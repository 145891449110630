/*$(function(){
	var container = $('.tt-form--imagefile'), inputFile = $('#hbd-file'), img, imgCrop, btn, txt = 'คลิกที่นี้ ! เพื่ออัพโหลดรูป', txtAfter = 'เปลี่ยนรูปใหม่' ;
    var icons = $('.tt-form-icon');
    var cropButton = $('.crop-image');
    var imageCropArea = $('#imgCropArea');
			
        if(!container.find('#upload').length){
            container.find('.tt-forms-input-file').append('<input type="button" value="'+txt+'" id="upload">');
            btn = $('#upload');
            container.prepend('<div class="image-wrapper"><img src="" class="hidden" alt="Uploaded file" id="uploadImg" width="100"></div>');
            img = $('#uploadImg');
            imgCrop = $('#uploadImgCrop');
        }

        btn.on('click', function(){
            img.animate({opacity: 0}, 300);
            imgCrop.animate({opacity: 0}, 300);
            inputFile.click();
            icons.hide();
            btn.addClass('active');
            cropButton.addClass('active');
        });

        inputFile.on('change', function(e){
            container.find('label').html( inputFile.val() );
            imageCropArea.find('label').html( inputFile.val() );
            
            var i = 0;
            for(i; i < e.originalEvent.srcElement.files.length; i++) {
                var file = e.originalEvent.srcElement.files[i], 
                    reader = new FileReader();

                reader.onloadend = function(){
                    img.attr('src', reader.result).animate({opacity: 1}, 500);
                    imageCropArea.attr('src', reader.result).animate({opacity: 1}, 500);
                }

                reader.readAsDataURL(file);
                img.removeClass('hidden');
                imageCropArea.removeClass('hidden');
            }
            
            btn.val( txtAfter );
        });
});



window.addEventListener('DOMContentLoaded', function () {
    var images = document.getElementById('imgCropArea');
    var avatar = document.getElementById('uploadImg');
    var cropBoxData;
    var canvasData;
    var cropper;

    $('#lightboxCrop').on('shown.bs.modal', function () {
      cropper = new Cropper(images, {
        aspectRatio: 1 / 1,
        autoCropArea: 0.5,
        ready: function () {
          //Should set crop box data first here
          cropper.setCropBoxData(cropBoxData).setCanvasData(canvasData);
        }
      });
    }).on('hidden.bs.modal', function () {
      cropBoxData = cropper.getCropBoxData();
      canvasData = cropper.getCanvasData();
      cropper.destroy();
    });

    document.getElementById('cropbtn').addEventListener('click', function () {
        var initialAvatarURL;
        var canvas;

        $('#lightboxCrop').modal('hide');
        initialAvatarURL = avatar.src;
    });
  });*/

  $(document).ready(function() {
    $('.select-textbox').select2({
      selectionCssClass: 'tt-select2-inner'
    });
});

  


    window.addEventListener('DOMContentLoaded', function () {
      var avatar = document.getElementById('avatar');
      var image = document.getElementById('image');
      var input = document.getElementById('input');
      var $progress = $('.progress');
      var $progressBar = $('.progress-bar');
      var $alert = $('.alert');
      var $modal = $('#lightboxCrop');
      var cropper;

      $('[data-toggle="tooltip"]').tooltip();

      input.addEventListener('change', function (e) {
        var files = e.target.files;
        var done = function (url) {
          input.value = '';
          image.src = url;
          $alert.hide();
          $modal.modal('show');
        };
        var reader;
        var file;
        var url;

        if (files && files.length > 0) {
          file = files[0];

          if (URL) {
            done(URL.createObjectURL(file));
          } else if (FileReader) {
            reader = new FileReader();
            reader.onload = function (e) {
              done(reader.result);
            };
            reader.readAsDataURL(file);
          }
        }
      });

      $modal.on('shown.bs.modal', function () {
        cropper = new Cropper(image, {
          aspectRatio: 1,
          viewMode: 3,
        });
      }).on('hidden.bs.modal', function () {
          cropper.destroy();
          cropper = null;
      });

      document.getElementById('cropbtn').addEventListener('click', function () {
        var initialAvatarURL;
        var canvas;

        if (cropper) {
          canvas = cropper.getCroppedCanvas({
            width: 900,
            height: 900,
          });
          initialAvatarURL = avatar.src;
          avatar.src = canvas.toDataURL();
          $progress.show();
          $alert.removeClass('alert-success alert-warning');

          canvas.toBlob(function (blob) {
            var formData = new FormData();

            formData.append('', blob, '');
            /*$.ajax('https://jsonplaceholder.typicode.com/posts', {
              method: 'POST',
              data: formData,
              processData: false,
              contentType: false,

              xhr: function () {
                var xhr = new XMLHttpRequest();

                xhr.upload.onprogress = function (e) {
                  var percent = '0';
                  var percentage = '0%';

                  if (e.lengthComputable) {
                    percent = Math.round((e.loaded / e.total) * 100);
                    percentage = percent + '%';
                    $progressBar.width(percentage).attr('aria-valuenow', percent).text(percentage);
                  }
                };

                return xhr;
              },

              success: function () {
                $alert.show().addClass('alert-success').text('Upload success');
              },

              error: function () {
                avatar.src = initialAvatarURL;
                $alert.show().addClass('alert-warning').text('Upload error');
              },

              complete: function () {
                $progress.hide();
              },
            });*/

          }); 
          $modal.modal('hide');
        }
      });
    });



    const tabs = document.querySelectorAll(".tab-radio-switch");
    const radio = document.querySelectorAll(".toggleTab");
    const radioGroup = document.querySelectorAll(".tab .tt-form-radios");
    
    for(var x=0; x < radio.length; x++){
      radio[x].addEventListener("change", function(){
        for(var y = 0; y < radioGroup.length; y++){
          radioGroup[y].value = "";
        }
      
        for(var i=0; i < tabs.length; i++){
          tabs[i].classList.add("d-none");
          
          if(this.checked && tabs[i].getAttribute("tabname") == this.value){
            tabs[i].classList.remove("d-none")
          }
        }
        
      });
    }




